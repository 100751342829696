.redirecting-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ensure it takes full height of the viewport */
}

.redirecting-text {
    font-size: 36px;
    font-weight: bold;
    color: #de3a8c; /* Or any other color you prefer */
    animation: pulse 0.5s infinite alternate; /* Apply animation */
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;