/* Mobile Styles */
@media only screen and (max-width: 576px) {
    .footer {
        // background-color: aqua;
        margin-top: -350px;
    }

    .footeronlyform {
        // background-color: red;
        bottom: 40px;
        position: absolute;
        // object-fit: contain;
        width: 100%;
        // top: 0;
        // width: 100px;
        align-items: center;
        justify-content: center;
        
    }

    .mobile-form-container {
        // background-color: green;
        // display: flex;
        justify-content: center;
        // align-items: center;
        // align-self: center;
        // justify-self: center;
        // height: 500px;
        /* Adjust height as needed */
        margin-left: 20px;
        margin-right: 20px;
    }

    .footer-content {
        border-top: 2px solid #ffffff;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // top: 0;
        // margin-top: 200px;
        // bottom: 20px;
        background-color: #9de83d;
    }

    // .mobile-para-container{
    //     background-color: #e83d3d;
    //     // min-width: fit-content;
    // }
    
    .mobileParaa {
        min-width: 270px;
        // width: 100%;
        font-family: BalsamiqSans-Bold;
        font-size: 13px;
        text-align: center;
        color: #ffffff;
        width: 346px;
        // margin-bottom: 25px;
        // background-color: red;
        // margin-top: 50px;
    }

    .playstore-img-mobile {
        // background-color: yellow;
        // margin-top: -1px; /* Adjust the margin as needed */
        width: 50%;
        /* Make it full width */
        text-align: center;
        /* Center the content inside the .playstore-img div */
        object-fit: contain;
        overflow: hidden;
    }

    .playstore-mobile {
        width: 100px;
        /* Adjust the width as needed */
        height: 100px;
        overflow: hidden;
    }

    .social-media {
        display: flex;
        gap: 20px;
        justify-content: center;
    }
}

@media only screen and (max-width: 844px){
  
    .mobileParaa {
        // width: 100%;
        font-family: BalsamiqSans-Bold;
        font-size: 13px;
        text-align: center;
        color: #ffffff;
        // margin-top: 20px;
        // margin-bottom: 20px;
        
    }
    .playstore-img-mobile {
    //    margin-bottom: 50px;
    }
    .social-media {
        margin-bottom: 50px;
    }

}

@media only screen and (min-width: 932px){
    .mobileParaa {
        // width: 100%;
        font-family: BalsamiqSans-Bold;
        font-size: 13px;
        text-align: center;
        color: #ffffff;
        // margin-top: 20px;
        // margin-bottom: 20px;
        // background-color: red;
        
    }
    .playstore-img-mobile {
    //    margin-bottom: 50px;
    }
    .social-media {
        margin-bottom: 600px;
    }
    
}
@media only screen and (max-width: 740px){
    .mobileParaa {
        // width: 100%;
        font-family: BalsamiqSans-Bold;
        font-size: 13px;
        text-align: center;
        color: #ffffff;
        // margin-top: 20px;
        // margin-bottom: 20px;
        // background-color: red;
        
    }
    .playstore-img-mobile {
    //    margin-bottom: 50px;
    }
    .social-media {
        // margin-bottom: 100px;
        margin-bottom: 50px;
    }
    
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;