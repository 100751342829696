/* Default styles for all screens */
.adventure-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 600px;
  background-image: url('../../../Assets/adventure-with-kidskite.png');
  // background-size: 20px 20px;
  // background-position: top -50% 5px;
  background-position: top -210px right -200px;
  background-repeat: no-repeat, no-repeat;
  // background-color: #0e9ff7;
  padding: 30px 0;
  margin-top: 10px;
}

.Adventure-heading {
  font-weight: 700;
  font-size: 36px;
  font-family: BalsamiqSans-bold;
  color: #0e9ff7;
  margin-bottom: 20px;
  background-size: 50px 50px, 50px 50px;
  background-repeat: no-repeat, no-repeat;
  // background-color: #deb03a;
}

.adventure-desktop {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Three columns */
  gap: 20px;
  justify-content: center;
  // background-color: rgb(255, 13, 0);
}

.adventure-card-head {
  font-weight: 700;
  font-size: 20px;
  color: black;
  font-family: BalsamiqSans-Regular;
}

.adventure-card-description {
  font-weight: 400;
  font-size: 14px;
  padding: 5px;
  justify-content: center;
  text-align: center;
  color: inherit;
  font-family: Poppins-Light;
  // background-color: #0e9ff7;
}

.adventure-card {
  width: 300px;
  /* Keep the width as before */
  height: 170px;
  /* Keep the height as before */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fdfdfd;
  border-radius: 6px;
  border: 2px solid #de3a8c;
  margin: 5px;
  color: rgb(84, 83, 83);
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
}


.adventure-card:hover {
  background-color: #de3a8c;
  color: #fff;
  /* Change text color to white on hover */
}

.adventure-card:hover .adventure-card-head {
  color: white;
}

.adventure-card-img .adventure-img {
  position: relative;
  height: 60px;
  width: 80px;
}

/* Media query for screens smaller than 768px (tablets) */
@media only screen and (max-width: 768px) {
  .adventure-container {
    padding: 20px 0px;
    max-height: 1400px;
    // background-color: #de3a8c;
    background-image: url('../../../Assets/mobile/bgbg.png');
    background-position: top -30px left 5px;
    // background-repeat: no-repeat, no-repeat;
  }

  .Adventure-heading-mobile {
    font-size: 24px;
    // background-color: #de3a8c;
    text-align: center;
    // margin: 20px;
    font-family: BalsamiqSans-bold;
    color: #0e9ff7;
    margin-bottom: 20px;
    background-size: 50px 50px, 50px 50px;
    background-repeat: no-repeat, no-repeat;
  }

  .Adventure-heading {
    display: none;
  }

  .adventure-desktop {
    grid-template-columns: repeat(2, 1fr);
    /* Two columns */
  }
}

/* Media query for screens smaller than 480px (mobile) */
@media only screen and (max-width: 480px) {
  .Adventure-heading-mobile {
    font-size: 24px;
    // background-color: #de3a8c;
    text-align: center;
    // margin: 20px;
    font-family: BalsamiqSans-bold;
    color: #0e9ff7;
    margin-bottom: 20px;
    background-size: 50px 50px, 50px 50px;
    background-repeat: no-repeat, no-repeat;
  }

  .adventure-desktop {
    grid-template-columns: repeat(1, 1fr);
    /* One column */
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;