@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;


@media screen and (max-width: 576px) {

  .aPageNine_mySwiper_Mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 380px;
    width: auto;
    // background-color: #029989;
    // margin-bottom: 500px; /* Adjusted margin-top to -50px (30px more up) */
    // z-index: 999;
    // margin-bottom: 1px;
  }

  .aPageNine_heading {
    font-weight: 700;
    font-size: 24px;
    color: #0e9ff7;
    // margin: -50px auto 20px; /* Adjusted top margin to -50px (30px more up) */
    text-align: center;
    // background-color: #c7bab2;
    font-family: BalsamiqSans-bold;
    margin-bottom: 15px;
    // height: 20vh; /* You may adjust this value based on your design */
  }

  .aPageNine_SwiperContainer_Mobile {
    align-items: center;
    width: 300px;
    margin: 10px auto;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: auto;

  }

  .card_img_client {
    width: 100%;
    margin: auto;
    height: 150px;
    // padding: 10px;
    padding-top: 15px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    // border-bottom: 10px solid #21ce86;
    // border-bottom-left-radius: 55px;
    // border-bottom-right-radius: 55px;.
    // border-radius: 10px;
    border-radius: 15px;
    background: @brand-white;
    // background-color: red;
  }

  .card_img_client img {
    border-radius: 15px;
  }

  .cards_content {
    min-width: min-content;
    margin: auto;
    // background-color: #4a6c7c;
    display: flex;
    flex-direction: column;
    /* Display children in a column layout */
    align-items: center;
    /* Center items horizontally */
    font-weight: bold;
    // font-size: 13px;
    color: aliceblue;
    padding: 20px;
    border-radius: 10px 10px 15px 15px;
    text-align: justify;
    hyphens: auto;
  }

  .cards_content h1 {
    margin: -15px 0;
    font-size: 18px;
    color: #de3a8c;
    // background-color: #029989;
    // width: 240px;
    // min-width: min-content;
    max-width: max-content;
    // width: 235px;
    text-align:center;
    font-family: ArialRoundedMtExtraBold;
  }

  .cards_content p {
    margin: 10;
    // padding: 0 10px;
    color: gray;
    font-size: 14px;
    margin-top: 30px;
  }

  .youtubeThumbnailContainer {
    // margin-top: 1px;
    /* Adjust the margin as needed */
  }

  .youtubeThumbnail {
    max-width: 80px;
    /* Set the maximum width to 50px */
    width: 100%;
    /* Make sure the image takes up 100% of the container width */
    height: auto;
    display: block;
    margin: 2px;
    border-radius: 10px;
  }


  .youtubeThumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    box-sizing: border-box;
    width: 100%;
    margin-top: 5px;
  }

  .youtubeThumbnail img {
    max-width: calc(100% - 20px);
    height: auto;
    display: block;
    margin: 2px;
    border-radius: 10px;
  }

  .aPageNine_bg {
    margin-top: 30px;
    margin-bottom: 380px;
    background-image: url("../../../Assets/youtube-channel-bg-star.png");
    background-repeat: no-repeat;
    background-size: 110%; /* Adjust the size as needed */
    background-position: top -15px right -23px; 
}


  // .swiper-pagination-bullet {
  //   width: 30px;
  //   height: 5px;
  //   background-color:#e383b4;
  //   border-radius: 0px;
  //   // background-color: red;
  //   // padding: 50px;
  //   // background-color: red;

  // }

  .swiper-pagination {
    position: absolute;
    margin-bottom: -10px; /* Adjust the distance from the bottom as needed */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // color: #ad5c85;
  }
  .swiper-pagination-bullet-active {
    // background-color: red;
    // background: @brand-primary;
    background-color: #de3a8c;
  }

}

@media screen and (min-width: 375px) {

  .aPageNine_heading {
    margin-top: 60px;
    // background-color: red;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;