/* ContactForm.less */


.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.bounce {
    animation: bounce 1s ease;
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: scale(.3);
    }
    50% {
        opacity: 1;
        transform: scale(1.05);
    }
    70% { transform: scale(.9); }
    100% { transform: scale(1); }
}


.formcontainer {
    // position: relative;
    margin: 0 auto;
    z-index: 1;
    // background-color: #bf450c;
}

.formcontainer h1 {
    margin-top: -180px;
    height: fit-content;
    width: 100%;
    font-size: 34px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    font-family: BalsamiqSans-Regular;
}

form {
    max-width: 400px;
    background-color: #0c7abf;
    height: 480px;
    margin: 0 auto;
    padding: 20px;
}

.messageInput {
    height: 70px;
    padding-bottom: 40px;
    // background-color: #0c7abf;
}

label {
    display: inline-block;
    margin-top: 18px;
    font-weight: 500;
    font-size: 17px;
    color: #737373;
    text-align: left;
    // background-color: #0c7abf;
}

input {
    min-width: 280px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 7px;
    display: block;
    color: #737373;
    margin-bottom: 5px; /* Add spacing between inputs */
    margin-top: 5px; /* Add spacing between inputs */
    // background-color: #0c7abf;
}

button {
    background-color: #2394cc;
    color: #fff;
    cursor: pointer;
    font-size: 27px;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
    min-width: 280px;
    font-weight: 500;
    border: none;
    border-bottom: 5px solid #147aac;
    margin-top: 20px;
    font-family: Poppins-Medium;
}

button:hover {
    background-color: #0c7abf;
}

.formdiv {
    background-color: #ffffff;
    border-radius: 35px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
    width: 85%;
    text-align: center;
    position: relative;
}

.formdiv.hide-form-content label,
.formdiv.hide-form-content button {
    display: none;
}

@media only screen  and (max-width: 756px){
    .formcontainer {
        position: relative;
        margin: 0 auto;
        z-index: 1;
        // background-color: #0c7abf;
        margin-bottom: -20px;
    }
    
    form {
        // max-width: 600vw;
        margin: 0 auto;
        padding: 20px;
        position: absolute;
        // margin-bottom: 50px;
    }
    
    .formdiv {
        height: 350px;
        // background-color: #e31010;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 10px;
        padding-right: 10px;
        // width: 82%;
        max-width: 270px;
        text-align: center;
        position: relative;
        
    }

    label {
        display: inline-block;
        // margin-top: 18px;
        font-weight: 500;
        font-size: 17px;
        color: #737373;
        text-align: left;
        margin-top: 10px;
        position: relative;
    }
    
    input {
        width: 100%;
        min-width: 226px;
        padding-left: 0;
        padding-right: 0;
        padding: 3px; 
        border-radius: 5px;
        display: block;
        color: #ff0000;
        position: relative;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: -3px;
        // background-color: #0c7abf;
    }
    
    button {
        min-width: 210px;
        position: relative;
        background-color: #2394cc;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        display: block;
        margin: 0 auto;
        border-radius: 10px;
        // min-width: 230px;
        // width: 100%;
        width: auto;
        font-weight: 500;
        border: none;
        border-bottom: 5px solid #147aac;
        margin-top: 26px;
        margin-bottom: 30px;
        font-family: Poppins-Medium;
    }

    .formcontainer h1 {
        font-size: 24px;
        // margin-left: 10px;
        color: #ffffff;
        font-family: BalsamiqSans-Regular;
    }
    
}

@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;