.youtube-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url("../../../Assets/youtube-channel-bg-star.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top -120px right -10px;
    margin-top: -40px;
    margin-bottom: -20px;
}

.crouseldiv {
    z-index: 4;
    max-width: 1100px;
    min-height: 400px;
    margin: 0 auto;
    position: relative;
    margin-top: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

}

.youtube-heading {
    font-weight: 700;
    font-size: 36px;
    color: #0e9ff7;
    margin: 30px auto;
    text-align: center;
    margin-bottom: -150px;
    font-family: BalsamiqSans-bold;
}

.next,
.previous {
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 5;
    position: relative; /* Change to relative */
}

.next {
    margin-left: 10px; /* Adds space between the right button and the cards */
}

.previous {
    margin-right: 10px; /* Adds space between the left button and the cards */
}

.youtube-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    max-width: 280px; /* Reduced width */
    height: 293px; /* Reduced height */
    text-align: center;
    margin: 0 10px;
}

.youtube-card.zoomed {
    transform: scale(1.20); /* Slightly reduced scale for middle card */
    transition: transform 0.5s ease-in-out;
    height:330px;
    width: 290px;
}

.youtube-card.zoomed h3 {
    font-size: 17px; /* Slightly smaller font size for zoomed heading */
}

.youtube-card.zoomed p {
    font-size: 12px; /* Slightly smaller font size for zoomed paragraph */
}

.YouTube-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.YouTube-symbol img {
    width: 53px;
    height: 38px;
}

.youtubeThumbnail {
    width: 90%; /* Reduced image width to avoid overflow */
    height: 145px; /* Reduced image height */
    margin-top: 5px;
    border-radius: 10px;
    overflow: hidden;
}

.youtubeThumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.youtube-card h3 {
    margin: 10px 0;
    font-size: 16px; /* Slightly smaller font size */
    color: #de3a8c;
    font-family: ArialRoundedMtExtraBold;
}

.youtube-card p {
    margin: 0;
    padding: 0 10px;
    color: gray;
    font-size: 11px; /* Slightly smaller font size */
}

@media only screen and (max-width: 768px) {
    .next,
    .previous {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (max-width: 576px) {
    .next,
    .previous {
        width: 30px;
        height: 30px;
    }
    .youtube-card {
        max-width: 200px;
        height: 240px;
    }
    .youtube-heading {
        font-size: 28px;
    }
}

@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;