body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "ArialRoundedMtExtraBold";
  /*Can be any text*/
  src: local("ArialRoundedMtExtraBold"),
    url("./fonts/ArialRoundedMtExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Mattilda";
  /*Can be any text*/
  src: local("Mattilda"),
    url("./fonts/Mattilda.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Light";
  /*Can be any text*/
  src: local("Poppins-Light"),
    url("./fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  /*Can be any text*/
  src: local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "BalsamiqSans-Bold";
  /*Can be any text*/
  src: local("BalsamiqSans-Bold"),
    url("./fonts/BalsamiqSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BalsamiqSans-Regular";
  /*Can be any text*/
  src: local("BalsamiqSans-Regular"),
    url("./fonts/BalsamiqSans-Regular.ttf") format("truetype");
}