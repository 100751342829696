/* Footer.less */

.image-container2 {
    // position: relative;
    // background-size: contain;
    margin-top: -150px;
}

.lastfooter {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: white;
    // background-color: #e90400;
}

.footer img {
    width: 100%;
    height: auto;
    /* Maintain the image's aspect ratio */
}

.lastfooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: white;
    // background-color: #e90400;
}

.column {
    flex: 1;
    margin: 0 10px;
}

.column1 {
    flex: 2;
    margin-bottom: 40px;
    text-align: start;
    font-family: BalsamiqSans-Bold;
    font-size: 18px;
}

.column1 p {
    margin-left: 60px;
}

.column2 {
    flex: 4;
    max-width: max-content;
    margin-bottom: 40px;
    justify-content: center;
    justify-items: center;
}

.column3 {
    flex: 1;
    display: flex;               /* Enable flexbox to align items horizontally */
    align-items: center;          /* Align the items vertically */
    gap: 20px;                    /* Fixed space between the icons */
    margin-right: 20px;
    margin-bottom: 40px;
}

/* Adjust the gap for larger screens if needed */
@media (min-width: 1200px) {
    .column3 {
        gap: 15px;           /* Increase gap slightly on larger screens */
    }
}
   


.socialicon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-right: 100px;
    // background-color: #00e927;
}

.lastfooter img {
    width: 30px;
    height: 30px;
    margin: 0;
    display: block;
}

.playstore {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    min-width: min-content;
}


.lastNavbar {
    // background-color: red;
    color: white;
    display: flex;
    justify-content: center;
    cursor: pointer;
    // margin: 0 auto;
    text-align: center;
    justify-items: center;
    position: absolute;
    bottom: 10px;
    width: 70%;
    padding: 5px;
    left: 50%;
    transform: translateX(-50%);
    // width: auto; /* Set width to auto to allow it to adapt to content */
    // min-width: 300px;
}

.navbarItem {
    margin-right: 30px;
    padding: 0 85px;
    // background-color: green; 
    justify-items: center;
    text-align: center;
    font-family: Poppins-Medium;
    color: #ffffff;
    text-decoration: none;
}

.footer {
    position: relative;
}

.image-container2 {
    position: relative;
    background-size: contain;
}

.teddyandformParent {
    // background-color: red;
    // max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    /* Adjust as needed */
    z-index: 15;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    margin-left: 20px;
    /* Add margin on the left side */
}

.parentTeddy {
    // background-color: #00e927;
    flex: 1;
    // border: transparent;
    display: flex;
    // margin-bottom: 10px;
    justify-content: flex-end;
    align-items: center;
    z-index: 11;
    max-width: 35%;
    margin-right: 20px;
    /* Add margin between teddy and form */
}

/* Add these styles to your CSS file or style block */
.teddy-dialog {
    width: 100%;
    /* Ensure each image takes the full width of the container */
    transition: transform 1s ease-in-out;
    /* Apply the transition effect */
}

.TeddyImg {
    display: flex;
    /* Allow the images to be in a row */
    overflow: hidden;
    /* Hide the overflow to ensure only one image is visible at a time */
}

.TeddyImg {
    width: 100%;
    max-width: 100%;
    // background-color: #1f00e9;
}

.teddy-dialog {
    max-width: 100%;
    height: 100%;
    // background-color: rgb(255, 0, 217);
}

.formParent {
    flex: 1;
    padding: 20px;
    /* Adjust as needed */
    box-sizing: border-box;
    /* Ensure padding is included in the width */
}

.mobilefooterimg {
    display: none;
}

.mobilePara {
    display: none;
}

@media only screen and (max-width: 576px) {
    .parentTeddy {
        display: none;
    }

    .teddyandformParent {
        height: 48vh;
    }

    .mobilefooterimg {
        display: block;
        width: 100%;
        height: auto;
    }

    .desktopfooterimg {
        display: none;
    }

    .image-container2 {
        margin-top: -350px;
    }

    .formParent {
        margin-bottom: 70px;
        margin-left: 20px;
    }

    .column1 {
        font-family: BalsamiqSans-Bold;
        font-size: 12px;
        background-color: yellow;
        margin-bottom: 90px;
        margin-left: 10px; /* Adjust margin-left as needed */
        width: calc(100% - 20px); /* Adjust width and margin as needed */
    }

    // .mobilePara {
    //     display: block;
    //     max-width: 100%;
    //     background-color: red;
    //     text-align: left;
    //     width: calc(100% - 80px); /* Adjust width and margin as needed */
    //     margin: 0 10px 20px; /* Adjust margin as needed */
    // }
    .desktopPara {
        display: none;
    }

    .lastfooter {
        display: block;
        position: absolute;
    }

    .lastNavbar {
        display: none;
    }

    .column3 {
        position: relative;
        margin-bottom: 90px;
        margin-left: 62px;
        width: 55%;
        height: 10vh;
    }

    // .playstore {
    //     max-width: 85%;
    // }

    // .social {
    //     width: 40px !important;
    //     height: 43px !important;
    // }

    // .socialicon {
    //     margin-bottom: -170px;
    //     margin-left: 50%; /* Center the social icons horizontally */
    //     transform: translateX(-50%); /* Center the social icons horizontally */
    // }
}


@media only screen and (max-width: 876px) {
    .mobilePara {
        // margin-left: 50px;
        // display: flex;
        // max-width: max-content;
        // background-color: red;
        // text-align: center;
    }

    .column1 {
        // background-color: #ffffff;
    }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;