/* E-Learning.less */

.image-container1 {
  width: 100%;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  text-align: center;
  // background-color: aquamarine;
  position: relative;
}

.banner-image {
  width: 100%;
  max-height: 120vh;
  object-fit: cover;
  margin-top: -150px;
}

.image-container1 h1 {
  margin: 0;
  padding: 10px;
  font-size: 70px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  font-family: ArialRoundedMtExtraBold;
  // background-color: aqua;
}

.para {
  margin: 0;
  padding: 10px;
  font-size: 1.4em;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  font-family: Poppins-Light;
}

.playstoreButtonIMg {
  position: absolute;
  width: 27%; /* Reduce the width further to bring buttons closer */
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between; /* Keep buttons close with equal space between */
}

.playstoreButtonIMg img {
  object-fit: contain;
  max-width: 100%; /* Adjust size to fit both buttons within the container */
  height: auto;
}

.image-container-mobile {
  display: none;
}

@media (min-width: 360px) and (max-width: 786px){
  .image-container1 {
    display: none;
  }

  .image-container-mobile {
    width: 100%;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    text-align: center;
    // background-color: aquamarine;
    // margin-bottom: 500px;
    object-fit: contain;
    position: relative;
    display: block; /* Show the mobile container within the media query */
  }

  .banner-image-mobile{
    width: 100%;
    object-fit: contain;
    // background-color: aqua;
  }

  .image-container-mobile h1 {
    margin: 0;
    padding: 10px;
    font-size: 38px;
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    color: white;
    font-family: ArialRoundedMtExtraBold;
    // background-color: aqua;
  }

  .para-mobile {
    color: white;
    margin: 0;
    padding: 10px;
    font-size: 11px;
    font-weight: 600;
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    // width: max-content;
    width: 330px;
    font-family: Poppins-Light;
    // background-color: red;
    text-align:justify;
  }

  .playstoreButtonIMg-mobile {
    position: absolute;
    width: 70%; /* Reduce width on mobile as well */
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between; /* Keep buttons close with equal space between */
  }
  
  .playstoreButtonIMg-mobile img {
    object-fit: contain;
    max-width: 100%; /* Adjust size to fit both buttons */
    height: auto;
  }
}

@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;