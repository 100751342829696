/* Your existing styles */

.FourthSectionBackground {
  display: grid;
  padding: 24px;
  padding-bottom: 60px;
  margin-top: 50px;
  background-image: url("../../../Assets/circles.png");
  background-repeat: no-repeat, no-repeat;
  background-size: 100%;
  background-position: top -1px right -10px;
}

.FourthSection_card_body {
  margin-top: -9px;
}

.mobileCard {
  display: none;
}

.Fourth_Section_benefits2 {
  display: none;
}

.Fourth_Section_benefits {
  font-weight: 900;
  font-size: 36px;
  font-family: BalsamiqSans-bold;
  color: #0e9ff7;
  margin-bottom: 100px;
}


.desktopCardContent {
  text-align: justify;
  hyphens: auto;
  font-size: 20px;
  color: #4e7884;
}

.mobileCardContent {
  display: none;
}

.FourthSection_img_card {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 300px;
  margin-right: 20px;
  margin-top: -60px;
}

.Fourth_Section_title_one,
.Fourth_Section_title_one_mobile {
  font-size: 26px;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
  font-family: BalsamiqSans-Regular;
  // background-color: yellow;
}

.outerCard {
  width: 774px;
  margin: 0 auto;
  border-radius: 50px;
  padding: 80px 100px 8px 100px;
  background-color: white;
}

.FourthSection_cards_div {
  max-width: 1250px;
  justify-self: center;
  align-self: center;
  padding: 50px;
  margin-top: -130px;
  text-align: left;
}

/* Media query for screens smaller than 576px (mobile) */
@media only screen and (max-width: 576px) {
  .FourthSectionBackground {
    background-image: url("../../../Assets/mobile/bg2updated.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-position: bottom 20px right 1px;
    background-size: 100% 79%;
    // margin-bottom: -80px;
    margin-top: 30px;
    // background-color: green;
    justify-content: center;
  }

  .desktopCard,
  .desktopCardContent {
    display: none;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .FourthSection_img_card {
    display: none;
  }

  .Fourth_Section_title_one_mobile {
    font-size: 16px;
    font-weight: 700;
    color: black;
    text-transform: uppercase;
    font-family: BalsamiqSans-Regular;
    text-align: center;
    margin-top: -20px;
  }

  .FourthSection_img_card_mobile {
    contain: content;
    display: flex;
    // justify-content: center;
    // border: 2px solid #f6f6f6;
    padding: 4px;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 60px;
    // background-color: red;
    margin-bottom: 10px;
  }

  .FourthSection_img_card_mobile img{
    // background-color: #0e9ff7;
    width: 200px;
    height: 200px;
  }

  .mobileCardContent {
    text-align: center;
    margin-top: 10px;
    hyphens: auto;
    font-size: 14px;
    color: #4e7884;
    font-weight: 500;
    display: block;
    // width: 250px;
    // background-color: #0e9ff7;
}


  .FourthSection_cards_div {
    padding: 12px;
    // margin-bottom: 24px;
    // margin-top: -80px;
    // background-color: red;
    // margin-right: 20px;
  }

  .Fourth_Section_benefits {
    font-size: 22px;
  }

  .Fourth_Section_benefits2 {
    display: flex;
    justify-content: center;
  }

  .FourthSection_benifit_text {
    /* width: 250px; */
    /* font-weight: 900; */
    /* font-size: 29px; */
    /* line-height: 32px; */
  }

  .outerCard {
    width: 100%;
    // padding: 30px;
    // border-radius: 15px;
    // background-color: #1ef70e;
  }
}


@media only screen and (max-width: 840px) {
  .FourthSectionBackground {
    // background-image: url("../../../Assets/mobile/bg2updated.png");
    // background-position: top;
    // background-repeat: no-repeat no-repeat;
    // background-position: top 50px right -50px;
    // background-color: #0e9ff7;
    // background-size: 100% 100%;
    // margin-bottom: -80px;
    margin-top: 30px;
    // background-color: green;
    justify-content: center;
  }

  .desktopCard,
  .desktopCardContent {
    display: none;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: #0ef725;
  }

  .FourthSection_img_card {
    display: none;
  }

  .Fourth_Section_title_one_mobile {
    font-size: 16px;
    font-weight: 700;
    color: black;
    text-transform: uppercase;
    font-family: BalsamiqSans-Regular;
    text-align: center;
    margin-top: -20px;
  }

  .FourthSection_img_card_mobile {
    contain: content;
    display: flex;
    // justify-content: center;
    // border: 2px solid #f6f6f6;
    padding: 4px;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 60px;
    // background-color: red;
    margin-bottom: 10px;
  }

  .FourthSection_img_card_mobile img{
    // background-color: #0e9ff7;
    width: 200px;
    height: 200px;
  }
  .mobileCardContent {
    text-align: center;
    margin-top: 10px;
    hyphens: auto;
    font-size: 14px;
    color: #4e7884;
    font-weight: 500;
    display: block;
    // width: 250px;
    // background-color: #0e9ff7;
}


  .FourthSection_cards_div {
    padding: 12px;
    // margin-bottom: 24px;
    // margin-top: -80px;
    // background-color: red;
    // margin-right: 20px;
  }

  .Fourth_Section_benefits {
    font-size: 24px;
    margin-bottom: 116px;
  }

  .Fourth_Section_benefits2 {
    display: flex;
    justify-content: center;
  }

  .FourthSection_benifit_text {
    /* width: 250px; */
    /* font-weight: 900; */
    /* font-size: 29px; */
    /* line-height: 32px; */
  }

  .outerCard {
    width: 100%;
    padding: 14px;
    border-radius: 15px;
    // background-color: #0e9ff7;
  }
}

/* Media query for screens between 721px and 1120px (tablet) */
@media only screen and (min-width: 721px) and (max-width: 1120px) {
  .outerCard {
    width: 100%;
    padding: 14px;
    margin: 0 auto;
    // background-color: blue;

  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;