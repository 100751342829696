// @import "~antd/dist/antd.less";
html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
}


// * {
//   font-family: "Montserrat", sans-serif;
// }
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;