/* Header.less */

.kidskite-header {
  background-color: #ffffff;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
  position: sticky;
  // width: 100%;
}

.kidskite-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 1rem 2rem;
  padding: 6px 20px;
  // background-color: #de3a8c;
}

.kidskite-logoStyling {
  margin-left: 80px;
  /* Adjust the margin for smaller screens */
  width: 93px;
  height: 70px;
}

.navbar {
  display: flex;
  margin: 0;
  padding: 0px;
  // margin-right: 185px;
  /* Adjust the margin for smaller screens */
  // position: static;
  // background-color: #de3a8c;

}

.navbar_item {
  display: inline-block;
  font-size: 18px;
  font-family: 'Poppins-Light';
  cursor: pointer;
  margin-right: 105px;
  /* Adjust the margin for smaller screens */
  color: gray;
}

.navbar_item:hover {
  color: #de3a8c;
}

/* Styles for Desktop Header */
.kidskite-header.desktop-header {
  display: block;
}

@media (max-width: 767px) {
  .kidskite-header.desktop-header {
    display: none;
  }
}

/* Styles for Mobile and Tablet Header */
.kidskite-header.mobile-tablet-header {
  display: none;
}

@media (max-width: 587px) {
  .kidskite-header.mobile-tablet-header {
    display: block;
  }

  .navbar {
    display: none;
  }

  .kidskite-header {
    display: none;
  }

  .kidskite-logoStyling {
    width: 50px;
    height: 40px;
    margin-left: 0;
  }
}

/* Styles for Mobile and Tablet Header */
@media (max-width: 767px) {
  .kidskite-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 16px;
    // position: relative;
    position: relative  ;
    // background-color: red;
    height: auto;
    
  }
  
  .kidskite-nav {
    // background-color: yellow;
    height: auto;
    // position:sticky;
  }

  .kidskite-logoStyling {
    margin-left: -20px;
    /* Adjust as needed */
    width: 50px;
    height: 40px;
  }

  .mobile-menu-icon {
    display: block;
    cursor: pointer;
    // padding: 10px;
    margin-right: -20px;

    // background-color: #de3a8c;

  }

  // .bar {
  //   width: 30px;
  //   height: 3px;
  //   background-color: #de3a8c;
  //   margin: 6px 0;
  //   transition: background-color 0.4s;
  // }

  // .open .bar:nth-child(1) {
  //   transform: rotate(-45deg) translate(-5px, 6px);
  // }

  // .open .bar:nth-child(2) {
  //   opacity: 0;
  // }

  // .open .bar:nth-child(3) {
  //   transform: rotate(45deg) translate(-5px, -6px);
  // }
  /* Default state */
  .bar {
    width: 30px;
    height: 3px;
    background-color: #de3a8c;
    margin: 6px 0;
    transition: 0.4s;
  }

  /* Open state */
  .open .bar:nth-child(1) {
    transform: rotate(-50deg) translate(-6px, 6px);
  }

  .open .bar:nth-child(2) {
    opacity: 0;
  }

  .open .bar:nth-child(3) {
    transform: rotate(50deg) translate(-6px, -6px);
  }

  .mobile-menu {
    border-top-style: solid;
    border-top-width: thin;
    border-top-color: #478688;
    position: fixed;
    top: 60px;
    /* Adjust as needed to keep the header visible */
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* Enable scrolling if content exceeds viewport height */
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .mobile-menu a {
    padding: 15px;
    color: gray;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.3s;
    // background-color: #10d83f;
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: #bae2f6;
  }

  .mobile-menu a:hover {
    color: #de3a8c;
    // background-color: transparent;
  }

  .open .mobile-menu {
    transform: translateX(0);
  }
  
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;