.fifthPage_bg_Home {
    justify-content: center;
    position: relative;
    // background-color: #58de3a;
    margin-top: -92px;
    // max-width: 1200px;
    height: 97vh;
    width: 100%;
    // margin-bottom: 100px;
}

.backgroundImage {
    // background-size: contain;
    height: 97vh;
    position: relative;
    width: 100%;
    // background-color: black;
}

.descriptionCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 20vh;
    position: absolute;
    left: 28vw;
    /* Adjust the left positioning */
    // bottom: 3vw;

    // background-color: red;
    height: 50vh;
    width: 70%;
    // width: max-content;
}



.fifth_Page_unlock_line {
    width: 100%;
    // margin-left: -26%;
    // margin-left: 15%;
    font-size: 44px;
    // font-weight: 500;
    // text-align: center;
    // width: 100%;
    // max-width: 900;
    // background-color: yellow;
    font-family: ArialRoundedMtExtraBold;
    position: relative;
    // margin-right: 20px;
    // margin-top: 30px;
    color: #ffffff;
}

.fifth_Page_unlock {
    width: 100%;
    // margin-right: 100%;
    // max-width: 900;
    // margin-left: -26%;
    font-size: 50px;
    // font-weight: 600;
    // text-align: center;
    position: relative;
    // width: 100%;
    font-family: ArialRoundedMtExtraBold;
    // background-color: green;
    margin-top: 105px;
    color: #ffffff;
    // margin-bottom: 250px;
    // text-align:justify;
    // justify-content: inherit;
}

.description {
    color: #ffffff;
    font-size: 19px;
    hyphens: auto;
    font-weight: 500;
    margin-top: 20px;
    margin-Right: 40px;
    font-family: Poppins-Medium;
    // background-color: rgb(211, 147, 82);
}

.playStoreImg {
    text-align: center;
    margin-top: 25px;
    margin-Right: 50px;

}

.playStoreImg img {
    // position: relative;
    width: 26%;
    /* Adjust the width as needed */
    height: 60%;
    /* Maintain aspect ratio */
    // max-width: 100%;
    // display: block; /* Center the image horizontally */
    margin: 0 auto;
    /* Center the image within its container */
}

.playStoreImg p {
    margin-top: 10px;
    font-size: 26px;
    color: #ffffff;
    font-style: italic;
    font-family: Poppins-Medium;
}

.backgroundImage_mobile {
    display: none;
}

.descriptionCol-mobile {
    display: none;
}

@media only screen and (max-width: 576px) {
    .backgroundImage {
        display: none;
    }

    .descriptionCol {
        background-color: red;
        display: none;
    }

    .backgroundImage_mobile {
        display: block;
        margin-bottom: 70px;
        width: 100%;
        height: auto;
        /* Make sure to add this line for responsive height */
        image-rendering: auto;

        // background-color: aqua;
    }

    .mobileimagebanner {
        // background-color: rgb(191, 255, 0);
        height: 65vh;
        width: 100%;
    }

    .descriptionCol-mobile {
        display: block;
        position: absolute;
        // top: 10vh; /* Adjust top position as needed */
        // left: 50%;
        // margin-left: 50%;
        margin-left: 30px;
        // transform: translateX(-50%);
        // background-color: red;
        padding: 10px;
        width: 90%;
        height: 60vh;
        text-align: center;
        margin-top: 20px;
        // justify-content: center;
    }

    .fifthPage_bg_Home {
        // background-color: #eb0000;
        width: 100%;
        margin: auto;
        height: auto;
        // margin-bottom: -220px;
        // margin-top: -400px;
        /* Adjust the margin as needed */
    }

    .fifth_Page_unlock-line-mobile {
        font-size: 24px;
        font-family: ArialRoundedMtExtraBold;
        position: relative;
        // margin-right: 20px;
        // margin-top: 30px;
        color: #ffffff;
        // background-color: rgb(77, 13, 239);
    }

    .fifth_Page_unlock-mobile {
        font-size: 29px;
        position: relative;
        width: 100%;
        font-family: ArialRoundedMtExtraBold;
        // background-color: green;
        color: #ffffff;
        justify-items: center;
        margin-left: -10px;
    }
    
    .fifth_Page_unlock_line-mobile {
        margin-left: -10px;
        width: 100%;
        font-size: 23px;
        font-family: ArialRoundedMtExtraBold;
        position: relative;
        // margin-right: 20px;
        // margin-top: 30px;
        color: #ffffff;
        // background-color: aqua;
    }
    
    .description-mobile {
        margin-left: -10px;
        font-size: 12px;
        color: #ffffff;
        hyphens: auto;
        // background-color: rgb(30, 0, 255);
        margin-top: 20px;
        // margin-Right: 30px;
        font-family: Poppins-Medium;
        width: 100%;
    }

    .playStoreImg-mobile {
        bottom: 50px;
        position: absolute;
        left: 50px; /* Adjust the right position as needed */
        // background-color: aqua;
        text-align: right;
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 50px;
    }
    
    .playStoreImg-mobile img {
        // background-color: rgb(17, 255, 0);
        width: 70%;
        margin-bottom: -15px;
    }
    
    .playStoreImg-mobile p {
        // background-color: rgb(123, 0, 255);
        font-size: 15px;
        color: #ffffff;
        font-style: italic;
        font-family: Poppins-Medium;
        margin-bottom: 10px;
        position: relative;
    }
    
}

@media only screen and (min-width: 390px) {
    .fifthPage_bg_Home {
        // background-color: #00eb27;
        width: 100%;
        margin: auto;
        margin-bottom: -70px;
        /* Adjust the margin as needed */
    }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;